import React, { useState, useEffect, useRef } from "react";
import { getDoc, getDocs, doc, setDoc, updateDoc, onSnapshot, collection, addDoc, deleteDoc } from 'firebase/firestore';
import { useNavigate, useParams, Link, useHistory, useNavigation } from 'react-router-dom';
import { getAuth, signOut, reauthenticateWithCredential, EmailAuthProvider, updatePassword } from "firebase/auth";
import { FaUser, FaArrowLeft, FaTv } from 'react-icons/fa';
import { db, app, storage } from "../../firebase";
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useLocation } from 'react-router-dom';
import { Menu } from "./AccueilAdmin";
import Logo from '../../assets/logo/logo-horizontale-v2.png';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.min.css';
import { useTranslation } from 'react-i18next';
import '../../css/style.css'
import imageCompression from 'browser-image-compression';

export function Fighter() {
    const { t } = useTranslation();
    const [displayFighter, setDisplayFighter] = useState(0); // 1 = combat passé, 2 = combat à venir

    /////// BOUTON
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const navigate = useNavigate();
    const toggleMenu = () => {
        setIsMenuOpen(prevState => !prevState);
    };

    const auth = getAuth();

    const handleLogout = () => { // DÉCONNEXION
        signOut(auth).then(() => {
            navigate("/login");
        }).catch((error) => {
            console.error("Error logging out: ", error);
        });
    };

    return (
        <div style={{
            display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: "#fcfaf5"
        }}>
            <div style={{
                position: 'absolute', top: '0', height: '60px', width: '100%', display: 'flex',
                justifyContent: 'space-between', alignItems: 'center', backgroundColor: '#ff3030', padding: '0 20px'
            }}>
                <div style={{
                    display: 'flex', alignItems: 'center'
                }}>
                    <img src={Logo} alt="Logo" style={{ height: '250px' }} />
                    <h2 style={{ margin: 0, color: 'white', fontSize: '1.2em' }}>FIGHTER</h2>
                </div>

                <div style={{
                    display: 'flex', alignItems: 'center'
                }}>
                    <button onClick={toggleMenu} style={{
                        padding: '8px 16px', border: 'none', backgroundColor: 'white', color: '#ff3030',
                        borderRadius: '25px', cursor: 'pointer', outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder', marginRight: '10px'
                    }}>
                        {isMenuOpen ? t('Close the menu') : t('Open the menu')}
                    </button>

                    <button onClick={handleLogout} style={{
                        background: 'none', border: '1px solid white', color: 'white', padding: '8px 16px',
                        borderRadius: '25px', cursor: 'pointer', fontWeight: 'bolder'
                    }}>
                        Logout
                    </button>
                </div>
            </div>


            {isMenuOpen && (
                <Menu />
            )}

            <div style={{ // DONNÉE FIGHTER
                marginTop: '100px', width: '60%', alignItems: 'center', justifyContent: 'center',
            }}>

                <div style={{ // BOUTONS
                    flexDirection: 'row', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%',
                }}>
                    <button // 
                        onClick={() => setDisplayFighter(1)}
                        style={{
                            padding: '10px 20px', border: 'none', backgroundColor: 'white', border: '2px solid #ff3030',
                            color: displayFighter === 1 ? 'white' : 'black', borderRadius: 50, marginTop: '10px', cursor: 'pointer',
                            outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder',
                            height: 50, width: '45%', fontSize: 15, backgroundColor: displayFighter === 1 ? '#ff3030' : 'white',

                        }}>
                        NEW FIGHTER
                    </button>

                    <button // 
                        onClick={() => setDisplayFighter(2)}
                        style={{
                            padding: '10px 20px', border: 'none', backgroundColor: 'white', border: '2px solid #ff3030',
                            color: displayFighter === 2 ? 'white' : 'black', borderRadius: 50, marginTop: '10px', cursor: 'pointer',
                            outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder',
                            height: 50, width: '45%', fontSize: 15, backgroundColor: displayFighter === 2 ? '#ff3030' : 'white', marginLeft: 20
                        }}>
                        UPDATE FIGHTER
                    </button>
                </div>

                {displayFighter === 1 ? <SubmitFighter /> : null}
                {displayFighter === 2 ? <UpdateFighter /> : null}


            </div>

        </div>
    )
}

export function SubmitFighter() {
    const { t } = useTranslation();
    const [NC, setNC] = useState(0);
    const [reach, setReach] = useState("");
    const [birthDay, setBirthDay] = useState("");
    const [birthMonth, setBirthMonth] = useState("");
    const [birthYear, setBirthYear] = useState("");
    const [born, setBorn] = useState("");
    const [division, setDivision] = useState("");
    const [countryFlag, setCountryFlag] = useState("");
    const [draw, setDraw] = useState(0);
    const [gender, setGender] = useState("");
    const [lose, setLose] = useState(0);
    const [loseByDec, setLoseByDec] = useState(0);
    const [loseByKoTko, setLoseByKoTko] = useState(0);
    const [loseBySub, setLoseBySub] = useState(0);
    const [lastName, setLastName] = useState("");
    const [org, setOrg] = useState("");
    const [outOf, setOutOf] = useState("");
    const [photo, setPhoto] = useState("");
    const [weight, setWeight] = useState("");
    const [firstName, setFirstName] = useState("");
    const [signStrikes, setSignStrikes] = useState(0);
    const [nickname, setNickname] = useState("");
    const [tailleFeet, setTailleFeet] = useState("");
    const [tailleInches, setTailleInches] = useState("");
    const [totalStrikes, setTotalStrikes] = useState(0);
    const [win, setWin] = useState(0);
    const [winByDec, setWinByDec] = useState(0);
    const [winByKoTko, setWinByKoTko] = useState(0);
    const [winBySub, setWinBySub] = useState(0);


    const [allDrapeaux, setAllDrapeaux] = useState([]); // Pour stocker tous les pays
    const [selectedPays, setSelectedPays] = useState(""); // Pays sélectionné par l'utilisateur
    const [orgs, setOrgs] = useState([])


    const genders = [{ value: "men", label: "MEN" }, { value: "women", label: "WOMEN" }];

    const [amateur, setAmateur] = useState(false);
    const amateurs = [{ value: false, label: "NO" }, { value: true, label: "YES" }];

    const divisions = gender === "men" ? [{ value: "1heavy", label: "HEAVYWEIGHT" },
    { value: "2lightheavy", label: "LIGHT-HEAVYWEIGHT" },
    { value: "3middle", label: "MIDDLEWEIGHT" },
    { value: "4welter", label: "WELTERWEIGHT" },
    { value: "5light", label: "LIGHTWEIGHT" },
    { value: "6feather", label: "FEATHERWEIGHT" },
    { value: "7bantam", label: "BANTAMWEIGHT" },
    { value: "8fly", label: "FLYWEIGHT" },
    { value: "catch", label: "CATCH WEIGHT" }] :
        [{ value: "6feather", label: "WOMEN FEATHERWEIGHT" },
        { value: "7bantam", label: "WOMEN BANTAMWEIGHT" },
        { value: "8fly", label: "WOMEN FLYWEIGHT" },
        { value: "9straw", label: "WOMEN STRAWWEIGHT" },
        { value: "10atom", label: "WOMEN ATOMWEIGHT" },
        { value: "catch", label: "CATCH WEIGHT" }];


    const etatsUSA = {
        'Alabama': 'AL',
        'Alaska': 'AK',
        'Arizona': 'AZ',
        'Arkansas': 'AR',
        'California': 'CA',
        'Colorado': 'CO',
        'Connecticut': 'CT',
        'Delaware': 'DE',
        'Florida': 'FL',
        'Georgia': 'GA',
        'Hawaii': 'HI',
        'Idaho': 'ID',
        'Illinois': 'IL',
        'Indiana': 'IN',
        'Iowa': 'IA',
        'Kansas': 'KS',
        'Kentucky': 'KY',
        'Louisiana': 'LA',
        'Maine': 'ME',
        'Maryland': 'MD',
        'Massachusetts': 'MA',
        'Michigan': 'MI',
        'Minnesota': 'MN',
        'Mississippi': 'MS',
        'Missouri': 'MO',
        'Montana': 'MT',
        'Nebraska': 'NE',
        'Nevada': 'NV',
        'New Hampshire': 'NH',
        'New Jersey': 'NJ',
        'New Mexico': 'NM',
        'New York': 'NY',
        'North Carolina': 'NC',
        'North Dakota': 'ND',
        'Ohio': 'OH',
        'Oklahoma': 'OK',
        'Oregon': 'OR',
        'Pennsylvania': 'PA',
        'Rhode Island': 'RI',
        'South Carolina': 'SC',
        'South Dakota': 'SD',
        'Tennessee': 'TN',
        'Texas': 'TX',
        'Utah': 'UT',
        'Vermont': 'VT',
        'Virginia': 'VA',
        'Washington': 'WA',
        'West Virginia': 'WV',
        'Wisconsin': 'WI',
        'Wyoming': 'WY'
    }

    const navigate = useNavigate();

    useEffect(() => { // RÉCUPÉRATION ORGA
        const fetchOrgs = async () => {

            const orgsDocRef = doc(db, "fightData", "organisations");

            try {
                const orgsDocSnap = await getDoc(orgsDocRef);

                if (orgsDocSnap.exists()) {
                    const { listOrgasNames } = orgsDocSnap.data(); // Assurez-vous que 'listOrgasNames' est correctement initialisé dans votre document
                    setOrgs(listOrgasNames);
                } else {
                    console.log("Document 'organisations' non trouvé.");
                }
            } catch (error) {
                console.error("Erreur lors de la récupération du document:", error);
            }
        };

        fetchOrgs();
    }, []);

    // Charger tous les pays au démarrage de l'application
    useEffect(() => {
        const drapeauCollection = collection(db, "Admin", "AdminFighter", "drapeau");
        const unsubscribe = onSnapshot(drapeauCollection, (querySnapshot) => {
            const drapeaux = [];
            querySnapshot.forEach((document) => {
                drapeaux.push(document.id);
            });
            setAllDrapeaux(drapeaux);
        });
        return () => unsubscribe();
    }, []);

    // Charger l'URL du drapeau lorsque l'utilisateur sélectionne un pays
    useEffect(() => {
        const fetchDrapeau = async () => {
            if (selectedPays) {
                const docRef = doc(db, "Admin", "AdminFighter", "drapeau", selectedPays);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    const data = docSnap.data();
                    setCountryFlag(data.image); // Assurez-vous que le champ s'appelle "image" dans Firestore
                } else {
                    console.log("No such document!");
                }
            }
        };

        fetchDrapeau();
    }, [selectedPays]);

    // record
    const [fullRecord, setFullRecord] = useState("");
    useEffect(() => {
        let regex = /(\d+)-(\d+)-(\d+)(?:, (\d+) NC)?/;
        const matches = fullRecord.match(regex);

        if (matches) {
            setWin(parseInt(matches[1], 10));
            setLose(parseInt(matches[2], 10));
            setDraw(parseInt(matches[3], 10));
            setNC(matches[4] ? parseInt(matches[4], 10) : 0);
        }
    }, [fullRecord]);

    // date de naissance
    const [fullDateOfBirth, setFullDateOfBirth] = useState("");

    const months = {
        Jan: 1,
        Feb: 2,
        Mar: 3,
        Apr: 4,
        May: 5,
        Jun: 6,
        Jul: 7,
        Aug: 8,
        Sep: 9,
        Oct: 10,
        Nov: 11,
        Dec: 12
    };

    useEffect(() => {
        let regex = /(\d{4})\s([A-Za-z]{3})\s(\d{2})/;
        const matches = fullDateOfBirth.match(regex);

        if (matches) {
            setBirthYear(parseInt(matches[1], 10));
            const monthString = matches[2];
            const monthNumber = months[monthString];
            setBirthMonth(monthNumber);
            setBirthDay(parseInt(matches[3], 10));
        }
    }, [fullDateOfBirth]);



    const handleBlurBorn = (e) => { // SI ETATS USA ALORS METTRE ABRÉVIATION + United States
        let value = e.target.value;

        // Remplacer le nom complet de l'état par son abréviation
        Object.entries(etatsUSA).forEach(([fullName, abbreviation]) => {
            // Vérifier si le nom de l'état est suivi d'une virgule ou se trouve à la fin de la chaîne
            const regex = new RegExp(`${fullName}(,|$)`);
            if (regex.test(value)) {
                value = value.replace(regex, abbreviation + "$1"); // Conserver la virgule s'il y en a une
                if (!value.includes("United States") && !value.includes("USA")) {
                    value += ", United States";
                }
            }
        });

        // Remplacer "USA" par "United States"
        if (value.includes("USA") && !value.includes("United States")) {
            value = value.replace("USA", "United States");
        }

        // Vérifier si la valeur ne contient pas "USA", "United States" ni un état des USA
        const containsUSA = value.includes("USA") || value.includes("United States");
        const containsState = Object.keys(etatsUSA).some(state => value.includes(state));
        if (!containsUSA && !containsState) {
            // Diviser la valeur en parties basées sur les virgules
            const parts = value.split(',').map(part => part.trim());

            // Vérifier si la valeur a trois parties
            if (parts.length === 3) {
                // Réduire à deux parties en enlevant la partie du milieu
                value = `${parts[0]}, ${parts[2]}`;
            }
        }

        setBorn(value);
    };

    const handleBlurOutOf = (e) => { // SI ETATS USA ALORS METTRE ABRÉVIATION + United States
        let value = e.target.value;

        // Remplacer le nom complet de l'état par son abréviation
        Object.entries(etatsUSA).forEach(([fullName, abbreviation]) => {
            // Vérifier si le nom de l'état est suivi d'une virgule ou se trouve à la fin de la chaîne
            const regex = new RegExp(`${fullName}(,|$)`);
            if (regex.test(value)) {
                value = value.replace(regex, abbreviation + "$1"); // Conserver la virgule s'il y en a une
                if (!value.includes("United States") && !value.includes("USA")) {
                    value += ", United States";
                }
            }
        });

        // Remplacer "USA" par "United States"
        if (value.includes("USA") && !value.includes("United States")) {
            value = value.replace("USA", "United States");
        }

        // Vérifier si la valeur ne contient pas "USA", "United States" ni un état des USA
        const containsUSA = value.includes("USA") || value.includes("United States");
        const containsState = Object.keys(etatsUSA).some(state => value.includes(state));
        if (!containsUSA && !containsState) {
            // Diviser la valeur en parties basées sur les virgules
            const parts = value.split(',').map(part => part.trim());

            // Vérifier si la valeur a trois parties
            if (parts.length === 3) {
                // Réduire à deux parties en enlevant la partie du milieu
                value = `${parts[0]}, ${parts[2]}`;
            }
        }

        setOutOf(value);
    };

    /// image fighter

    const [imageFile, setImageFile] = useState(null);
    const [noFileSelected, setNoFileSelected] = useState(true);

    const [croppedImageBlob, setCroppedImageBlob] = useState(null);
    const imageRef = useRef();
    const cropperRef = useRef();

    // Gestionnaire pour confirmer le rognage
    const handleCropConfirm = () => {
        cropperRef.current.getCroppedCanvas().toBlob((blob) => {
            setCroppedImageBlob(blob); // Stocker le blob pour la télécharger plus tard
        }, 'image/webp');
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            setImageFile(file);
            setNoFileSelected(false);

            // Lire l'image et l'afficher pour le recadrage
            const reader = new FileReader();
            reader.onload = (e) => {
                imageRef.current.src = e.target.result;
                if (cropperRef.current) {
                    cropperRef.current.destroy(); // Détruire l'instance précédente
                }
                cropperRef.current = new Cropper(imageRef.current, {
                    aspectRatio: 600 / 300, // Ratio d'aspect souhaité
                    //... autres options
                });
            };
            reader.readAsDataURL(file);
        }
    };
    const fileInputRef = useRef();// sélectionneur d'image
    ////

    const [alreadyExist, setAlreadyExist] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        let imageUrl = "";

        if (imageFile) {

            if (croppedImageBlob) {
                try {
                    // Options de compression
                    const options = {
                        maxSizeMB: 0.040, // Taille maximale en Mo (vous pouvez ajuster en fonction de votre besoin)
                        maxWidthOrHeight: 780, // Largeur/hauteur maximale (ajustez selon vos besoins)
                        useWebWorker: true, // Utiliser les Web Workers pour améliorer les performances
                        fileType: 'image/webp', // Convertir en WebP
                    };

                    // Compresser l'image rognée
                    const compressedBlob = await imageCompression(croppedImageBlob, options);

                    const storagePath = `fighterImage/${lastName} ${firstName}`;
                    const storageRef = ref(storage, storagePath);

                    const snapshot = await uploadBytes(storageRef, compressedBlob, { contentType: 'image/webp' });
                    imageUrl = await getDownloadURL(snapshot.ref);
                    // imageUrl est l'URL de l'image téléchargée
                } catch (error) {
                    console.error("Erreur lors du téléchargement de l'image:", error);
                    return; // Quittez la fonction si le téléchargement échoue
                }
            } else {
                setNoFileSelected(true);
                console.log('Veuillez confirmer le rognage de l’image');
                return;
            }
        } else {
            setNoFileSelected(true);
            console.log('Aucun fichier sélectionné');

        }

        try {

            const docOrgaFighterRef = doc(db, "fightData", "organisations", org, 'fighter', division, `${lastName} ${firstName}`);
            const docRef = doc(db, "fighterData", `${lastName} ${firstName}`);

            const docSnap1 = await getDoc(docRef);

            if (docSnap1.exists()) {
                setAlreadyExist(true);
                alert("ERROR: FIGHTER ALREADY EXIST ");
                return;
            }

            // Fonction pour extraire le pays seulement si la chaîne est valide
            const extractCountry = (locationString) => {
                if (locationString && locationString.trim() !== "") { // Vérification si non undefined et non vide
                    const parts = locationString.split(',');
                    return parts[parts.length - 1].trim(); // Récupère le dernier élément et supprime les espaces
                }
                return null; // Retourne null si la chaîne est vide ou undefined
            };

            // Récupération des pays uniquement si born est valides
            const bornCountry = extractCountry(born);

            // Utilisation d'un Set pour garantir l'unicité des pays
            const countriesSet = new Set();

            if (bornCountry) countriesSet.add(bornCountry);
            if (selectedPays) countriesSet.add(selectedPays);

            // Conversion du Set en tableau
            const countries = Array.from(countriesSet);

           

            await setDoc(docRef, {
                amateur: Boolean(amateur),
                NC: Number(NC),
                reach: `${reach}"`,
                birthDate: `${birthMonth}/${birthDay}/${birthYear}`,
                born: {
                    en: born
                },
                // Ajout du tableau countries si au moins un pays est valide
                countries: countries.length > 0 ? countries : null,
                countryFlag,
                division,
                draw: Number(draw),
                gender,
                id: `${lastName} ${firstName}`,
                lose: Number(lose),
                loseByDec: Number(loseByDec),
                loseByKoTko: Number(loseByKoTko),
                loseBySub: Number(loseBySub),
                lastName,
                orga: org,
                outOf: {
                    en: outOf
                },
                photo: imageUrl ? imageUrl : photo,
                weight: Number(weight),
                firstName,
                signStrikes: Number(signStrikes),
                nickname,
                height: `${tailleFeet}'${tailleInches}"`,
                totalStrikes: Number(totalStrikes),
                win: Number(win),
                winByDec: Number(winByDec),
                winByKoTko: Number(winByKoTko),
                winBySub: Number(winBySub),


            });

            await setDoc(docOrgaFighterRef, {
                fighterRef: docRef
            });


            setImageFile(null);
            fileInputRef.current.value = "";
            setFullDateOfBirth(""); setFullRecord(""); setAlreadyExist(false);
            setNC(0); setReach(""); setBirthDay(""); setBirthMonth(""); setBirthYear(""); setBorn("");
            setCountryFlag(""); setDraw(0); setLose(0); setLoseByDec(0); setLoseByKoTko(0); setLoseBySub(0);
            setLastName(""); setOutOf(""); setPhoto(""); setWeight(""); setFirstName(""); setSignStrikes(0); setSelectedPays("");
            setNickname(""); setTailleFeet(""); setTailleInches(""); setTotalStrikes(0); setWin(0); setWinByDec(0); setWinByKoTko(0);
            setWinBySub(0);
            alert("Fighter soumis avec succès !");
        } catch (error) {
            console.error("Error adding document: ", error);
        }

    };



    return (
        <div style={{
            display: 'flex', justifyContent: 'center', alignItems: 'center',
        }}>

            <div style={{
                display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px', fontFamily: "'Arial', sans-serif", justifyContent: 'center',
                borderRadius: '10px', width: '600px', margin: '50px auto',
            }}>

                <h2 style={{ color: '#ff3030', marginBottom: '20px' }}>{t("Submit fighter")}</h2>

                <form onSubmit={handleSubmit} style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    {alreadyExist ?
                        <label style={{ color: 'red', fontWeight: 'bold', marginVertical: '10px' }}>Already exist: verify if it's the same fighter in "update fighter", if it's a different fighter with the same name, add the nickname to the first name when adding the new fighter</label>
                        : null}
                    <div style={{ // nom/prénom
                        width: '100%', marginBottom: '10px', flexDirection: 'row', display: 'flex'
                    }}>
                        <div style={{ // prénom
                            width: '100%', marginBottom: '10px'
                        }}>
                            <label>{t("First name")} :</label>

                            <div style={{ // prénom
                                width: '100%', marginBottom: '10px'
                            }}>
                                <input
                                    type="text"
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    onBlur={() => setFirstName((prev) => prev.trim())}
                                    style={{
                                        width: '60%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                    }} />
                            </div>
                        </div>


                        <div style={{ // nom
                            width: '100%', marginBottom: '10px'
                        }}>
                            <label>{t("Last name")} :</label>

                            <div style={{ // nom
                                width: '100%', marginBottom: '10px'
                            }}>
                                <input
                                    type="text"
                                    value={lastName}
                                    onChange={(e) => setLastName(e.target.value)}
                                    onBlur={() => setLastName((prev) => prev.trim())}
                                    required
                                    style={{
                                        width: '60%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                    }}
                                />
                            </div>
                        </div>

                        <div style={{ // nickname
                            width: '100%', marginBottom: '10px'
                        }}>
                            <label>nickname :</label>

                            <div style={{ // nickname
                                width: '100%', marginBottom: '10px'
                            }}>
                                <input
                                    type="text"
                                    value={nickname}
                                    onChange={(e) => setNickname(e.target.value)}

                                    style={{
                                        width: '40%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                    }} />
                            </div>
                        </div>


                    </div>

                    <div style={{ // record
                        width: '100%', marginBottom: '10px', flexDirection: 'row', display: 'flex'
                    }}>

                        <div style={{ // full record
                            width: '100%', marginBottom: '10px'
                        }}>
                            <label>full record :</label>

                            <div style={{ // full record
                                width: '100%', marginBottom: '10px'
                            }}>
                                <input
                                    type="text"
                                    value={fullRecord}
                                    onChange={(e) => setFullRecord(e.target.value)}

                                    style={{
                                        width: '40%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                    }} />
                            </div>
                        </div>

                        <div style={{ // full record
                            width: '50%', marginBottom: '10px', marginTop: 25,
                        }}>
                            <label>or</label>
                        </div>

                        <div style={{ // victoire
                            width: '100%', marginBottom: '10px'
                        }}>
                            <label>{t("Victories")} :</label>
                            <div style={{ // victoire
                                width: '100%', marginBottom: '10px'
                            }}>
                                <input
                                    type="text"
                                    value={win}
                                    onChange={(e) => setWin(e.target.value)}
                                    required
                                    style={{
                                        width: '20%', padding: '10px 10px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                    }} />
                            </div>
                        </div>

                        <div style={{ // défaite
                            width: '100%', marginBottom: '10px'
                        }}>
                            <label>{t("Defeats")} :</label>
                            <div style={{ // défaite
                                width: '100%', marginBottom: '10px'
                            }}>
                                <input
                                    type="text"
                                    value={lose}
                                    onChange={(e) => setLose(e.target.value)}
                                    required
                                    style={{
                                        width: '20%', padding: '10px 10px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                    }} />
                            </div>
                        </div>

                        <div style={{ // draw
                            width: '100%', marginBottom: '10px'
                        }}>
                            <label>{t("Draws")} :</label>
                            <div style={{ // draw
                                width: '100%', marginBottom: '10px'
                            }}>
                                <input
                                    type="text"
                                    value={draw}
                                    onChange={(e) => setDraw(e.target.value)}

                                    style={{
                                        width: '20%', padding: '10px 10px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                    }} />
                            </div>
                        </div>

                        <div style={{ // NO CONTEST
                            width: '100%', marginBottom: '10px'
                        }}>
                            <label>NO CONTEST :</label>
                            <input
                                type="text"
                                value={NC}
                                onChange={(e) => setNC(e.target.value)}
                                required
                                style={{
                                    width: '20%', padding: '10px 10px', border: '1.5px solid #ff3030', margin: '5px 0',
                                    borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                }} />
                        </div>
                    </div>

                    <div style={{ // Organisation
                        width: '100%', marginBottom: '10px'
                    }}>
                        <label>Organization :</label>
                        <label style={{ color: '#313131', fontSize: 14 }}> ( {t("if retirement, do not select an organization")} )</label>
                        <select
                            value={org}
                            onChange={(e) => setOrg(e.target.value)}
                            style={{
                                width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                borderRadius: '5px', outline: 'none',
                            }}
                        >
                            <option value="">{t("Select an organization")}</option>
                            {orgs.map((org, index) => (
                                <option key={index} value={org}>
                                    {org}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div style={{ // AMATEUR
                        width: '100%', marginBottom: '10px'
                    }}>
                        <label style={{ marginRight: 10 }}>{t('Amateur')} :</label>

                        <select
                            value={amateur}
                            onChange={(e) => setAmateur(e.target.value === 'true')}
                            required
                            style={{
                                width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                borderRadius: '5px', outline: 'none',
                            }}
                        >
                            <option value="">{t("Select an answer")}</option>
                            {amateurs.map((amateur, index) => (
                                <option key={index} value={amateur.value}>
                                    {amateur.label}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div style={{ // Genre
                        width: '100%', marginBottom: '10px'
                    }}>
                        <label style={{ marginRight: 10 }}>{t("Gender")} :</label>

                        <select
                            value={gender}
                            onChange={(e) => setGender(e.target.value)}
                            required
                            style={{
                                width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                borderRadius: '5px', outline: 'none',
                            }}
                        >
                            <option value="">{t("Select a gender")}</option>
                            {genders.map((gender, index) => (
                                <option key={index} value={gender.value}>
                                    {gender.label}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div style={{ // division
                        width: '100%', marginBottom: '10px'
                    }}>
                        <label>{t("Division")} :</label>
                        <select
                            value={division}
                            onChange={(e) => setDivision(e.target.value)}
                            required
                            style={{
                                width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                borderRadius: '5px', outline: 'none',
                            }}
                        >
                            <option value="">{t("Select a division")}</option>
                            {divisions.map((division, index) => (
                                <option key={index} value={division.value}>
                                    {division.label}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div style={{ // DRAPEAU
                        width: '100%', marginBottom: '10px'
                    }}>
                        <label>{t("Flag")} :</label>
                        <select
                            value={selectedPays}
                            onChange={(e) => setSelectedPays(e.target.value)}

                            style={{
                                width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                borderRadius: '5px', outline: 'none',
                            }}
                        >
                            <option value="">{t("Select a flag")}</option>
                            {allDrapeaux.map((pays, index) => (
                                <option key={index} value={pays}>
                                    {pays}
                                </option>
                            ))}
                        </select>
                    </div>


                    <div style={{
                        width: '100%', marginBottom: '10px' // PHOTO
                    }}>
                        <label>{t("Photo")} :</label>
                        <input
                            type="text"
                            value={photo}
                            onChange={(e) => setPhoto(e.target.value)}
                            style={{
                                width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                            }} />
                    </div>

                    <div style={{
                        width: '50%', marginBottom: '10px',
                    }}>
                        <label>or</label>
                    </div>

                    <div style={{ // ou
                        width: '100%', marginBottom: '10px',
                    }}>
                        <label htmlFor="file-upload" style={{ display: 'block', marginBottom: '10px' }}>
                            {t("Image to download")} :
                        </label>
                        <input
                            ref={fileInputRef}
                            id="file-upload"
                            type="file"
                            onChange={handleImageChange}
                            style={{
                                width: '90%', padding: '10px 15px', border: '1px solid #cccccc',
                                borderRadius: '5px', outline: 'none',
                            }} />
                        {noFileSelected ? <label style={{ color: 'red', fontSize: 13 }}>{t("No file selected")}</label> : null}
                    </div>

                    {!noFileSelected ?
                        <button style={{ width: '50%', marginBottom: '10px' }} type="button" onClick={handleCropConfirm}>{t("Confirm the crop")}</button>
                        : null}

                    {imageRef && !noFileSelected ?
                        <div style={{ width: '500px', height: '500px', overflow: 'hidden' }}>
                            <img ref={imageRef} style={{ maxWidth: '100%', maxHeight: '100%' }} alt="Preview" />
                        </div>
                        : null}

                    <div style={{ // DATE de naissance
                        width: '100%',
                    }}>
                        <label>{t("Date of birth")} :</label>

                        <div style={{
                            width: '70%', marginBottom: '10px', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', display: 'flex',
                        }}>

                            <div style={{ // full record
                                width: '90%', marginBottom: '10px'
                            }}>


                                <div style={{ // full record
                                    width: '100%', marginBottom: '10px'
                                }}>
                                    <input
                                        type="text"
                                        value={fullDateOfBirth}
                                        onChange={(e) => setFullDateOfBirth(e.target.value)}

                                        style={{
                                            width: '40%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                            borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                        }} />
                                </div>
                            </div>

                            <div style={{ // full record
                                width: '50%', marginBottom: '10px', marginTop: 25,
                            }}>
                                <label>or</label>
                            </div>

                            <div style={{ // MOIS
                                marginBottom: '10px', flexDirection: 'column', display: 'flex',
                            }}>
                                <input
                                    type="text"
                                    value={birthMonth}
                                    onChange={(e) => setBirthMonth(e.target.value)}
                                    required
                                    style={{
                                        width: '40%', padding: '10px 5px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                    }} />
                                <label style={{ fontSize: 15, }}>{t("Month")}</label>

                            </div>



                            <div style={{ // jour
                                marginBottom: '10px', flexDirection: 'column', display: 'flex', marginLeft: 10,
                            }}>
                                <input
                                    type="text"
                                    value={birthDay}
                                    onChange={(e) => setBirthDay(e.target.value)}
                                    required
                                    style={{
                                        width: '40%', padding: '10px 5px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                    }} />
                                <label style={{ fontSize: 15, }}>{t("Day")}</label>
                            </div>



                            <div style={{ // année
                                marginBottom: '10px', flexDirection: 'column', display: 'flex', marginLeft: 10,
                            }}>
                                <input
                                    type="text"
                                    value={birthYear}
                                    onChange={(e) => setBirthYear(e.target.value)}
                                    required
                                    style={{
                                        width: '70%', padding: '10px 10px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                    }} />
                                <label style={{ fontSize: 15, }}>{t("Year")}</label>
                            </div>
                        </div>


                    </div>

                    <div style={{ // born
                        width: '100%', marginBottom: '10px'
                    }}>
                        <label>{t("Born in")} :</label>

                        <div style={{ //
                            width: '100%', marginBottom: '10px',
                        }}>
                            <input
                                type="text"
                                value={born}
                                onChange={(e) => setBorn(e.target.value)}
                                required
                                onBlur={handleBlurBorn}
                                style={{
                                    width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                    borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                }} />
                        </div>
                    </div>

                    <div style={{ // FIGHTING OUT OF
                        width: '100%', marginBottom: '10px'
                    }}>
                        <label>FIGHTING OUT OF :</label>

                        <div style={{ // born
                            width: '100%', marginBottom: '10px'
                        }}>
                            <input
                                type="text"
                                value={outOf}
                                onChange={(e) => setOutOf(e.target.value)}
                                required
                                onBlur={handleBlurOutOf}
                                style={{
                                    width: '40%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                    borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                }} />
                        </div>
                    </div>

                    <div style={{ // weight
                        width: '100%', marginBottom: '10px'
                    }}>
                        <label>weight :</label>

                        <div style={{ // weight
                            width: '100%', marginBottom: '10px'
                        }}>
                            <input
                                type="text"
                                value={weight}
                                onChange={(e) => setWeight(e.target.value)}
                                required
                                style={{
                                    width: '40%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                    borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                }} />
                        </div>
                    </div>

                    <div style={{ // height
                        width: '100%', marginBottom: '10px'
                    }}>
                        <label>height :</label>

                        <div style={{ // feet
                            width: '100%', marginBottom: '10px', flexDirection: 'row'
                        }}>
                            <input
                                type="text"
                                value={tailleFeet}
                                onChange={(e) => setTailleFeet(e.target.value)}
                                required
                                style={{
                                    width: '5%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                    borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                }} />
                            <label style={{ marginLeft: 5, fontSize: 30 }}>'</label>
                            <input
                                type="text"
                                value={tailleInches}
                                onChange={(e) => setTailleInches(e.target.value)}
                                required
                                style={{
                                    width: '5%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                    borderRadius: '5px', outline: 'none', marginLeft: 5, fontSize: 15, fontWeight: 'bold'
                                }} />
                            <label style={{ marginLeft: 5, fontSize: 30 }}>"</label>
                        </div>




                    </div>


                    <div style={{ // reach
                        width: '100%', marginBottom: '10px'
                    }}>
                        <label>reach :</label>

                        <div style={{
                            width: '100%', marginBottom: '10px', flexDirection: 'row'
                        }}>

                            <input
                                type="text"
                                value={reach}
                                onChange={(e) => setReach(e.target.value)}
                                required
                                style={{
                                    width: '10%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                    borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                }} />
                            <label style={{ marginLeft: 5, fontSize: 30 }}>"</label>
                        </div>

                    </div>




                    <div style={{ // strike
                        width: '100%', marginBottom: '10px'
                    }}>


                        <div style={{
                            width: '100%', marginBottom: '10px', flexDirection: 'row'
                        }}>
                            <label>Total strike : </label>
                            <div style={{ // Total strike
                                width: '100%', marginBottom: '10px'
                            }}>
                                <input
                                    type="text"
                                    value={totalStrikes}
                                    onChange={(e) => setTotalStrikes(e.target.value)}
                                    required
                                    style={{
                                        width: '40%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                    }} />
                            </div>

                            <label>Significative strike : </label>

                            <div style={{ // Significative strike
                                width: '100%', marginBottom: '10px'
                            }}>
                                <input
                                    type="text"
                                    value={signStrikes}
                                    onChange={(e) => setSignStrikes(e.target.value)}
                                    required
                                    style={{
                                        width: '40%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                    }} />
                            </div>
                        </div>




                    </div>

                    <div style={{ // WIN BY
                        width: '100%', marginBottom: '10px'
                    }}>
                        <label>{t("Victories by")} :</label>

                        <div style={{
                            width: '75%', marginBottom: '10px', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', display: 'flex',
                        }}>
                            <div style={{ // ko tko
                                marginBottom: '10px', flexDirection: 'column', display: 'flex',
                            }}>

                                <input
                                    type="text"
                                    value={winByKoTko}
                                    onChange={(e) => setWinByKoTko(e.target.value)}
                                    required
                                    style={{
                                        width: '20%', padding: '10px 10px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                    }} />
                                <label>KO/TKO</label>

                            </div>

                            <div style={{ // soumission
                                marginBottom: '10px', flexDirection: 'column', display: 'flex',
                            }}>

                                <input
                                    type="text"
                                    value={winBySub}
                                    onChange={(e) => setWinBySub(e.target.value)}
                                    required
                                    style={{
                                        width: '20%', padding: '10px 10px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                    }} />
                                <label>Submission</label>

                            </div>

                            <div style={{ // décision
                                marginBottom: '10px', flexDirection: 'column', display: 'flex',
                            }}>

                                <input
                                    type="text"
                                    value={winByDec}
                                    onChange={(e) => setWinByDec(e.target.value)}
                                    required
                                    style={{
                                        width: '20%', padding: '10px 10px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                    }} />
                                <label>Decision</label>
                            </div>
                        </div>
                    </div>

                    <div style={{ // lose BY
                        width: '100%', marginBottom: '10px'
                    }}>
                        <label>{t("Defeats by")} :</label>

                        <div style={{
                            width: '75%', marginBottom: '10px', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', display: 'flex',
                        }}>
                            <div style={{ // ko tko
                                marginBottom: '10px', flexDirection: 'column', display: 'flex',
                            }}>

                                <input
                                    type="text"
                                    value={loseByKoTko}
                                    onChange={(e) => setLoseByKoTko(e.target.value)}
                                    required
                                    style={{
                                        width: '20%', padding: '10px 10px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                    }} />
                                <label>KO/TKO</label>

                            </div>

                            <div style={{ // soumission
                                marginBottom: '10px', flexDirection: 'column', display: 'flex',
                            }}>

                                <input
                                    type="text"
                                    value={loseBySub}
                                    onChange={(e) => setLoseBySub(e.target.value)}
                                    required
                                    style={{
                                        width: '20%', padding: '10px 10px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                    }} />
                                <label>Submission</label>

                            </div>

                            <div style={{ // décision
                                marginBottom: '10px', flexDirection: 'column', display: 'flex',
                            }}>

                                <input
                                    type="text"
                                    value={loseByDec}
                                    onChange={(e) => setLoseByDec(e.target.value)}
                                    required
                                    style={{
                                        width: '20%', padding: '10px 10px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                    }} />
                                <label>Decision</label>

                            </div>
                        </div>
                    </div>

                    <button
                        type="submit"
                        style={{
                            padding: '10px 20px', border: 'none', backgroundColor: '#ff3030',
                            color: 'white', borderRadius: '25px', marginTop: '10px', cursor: 'pointer',
                            outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder'
                        }}
                    >
                        {t("Submit")}
                    </button>
                </form>
            </div>
        </div>

    );
}

export function UpdateFighter() {
    const { t } = useTranslation();
    const [fighterData, setFighterData] = useState([]);
    const [id, setId] = useState('');
    const [NC, setNC] = useState('')
    const [reach, setReach] = useState("");
    const [birthDay, setBirthDay] = useState("");
    const [birthMonth, setBirthMonth] = useState("");
    const [birthYear, setBirthYear] = useState("");
    const [born, setBorn] = useState("");
    const [division, setDivision] = useState("");
    const [countries, setCountries] = useState([]);
    const [countryFlag, setCountryFlag] = useState("");
    const [draw, setDraw] = useState("");
    const [gender, setGender] = useState("");
    const [lose, setLose] = useState("");
    const [loseByDec, setLoseByDec] = useState("");
    const [loseByKoTko, setLoseByKoTko] = useState("");
    const [loseBySub, setLoseBySub] = useState("");
    const [lastName, setLastName] = useState("");
    const [org, setOrg] = useState("");

    const [outOf, setOutOf] = useState("");
    const [photo, setPhoto] = useState("");
    const [weight, setWeight] = useState("");
    const [firstName, setFirstName] = useState("");
    const [signStrikes, setSignStrikes] = useState("");
    const [nickname, setNickname] = useState("");
    const [tailleFeet, setTailleFeet] = useState("");
    const [tailleInches, setTailleInches] = useState("");
    const [totalStrikes, setTotalStrikes] = useState("");
    const [win, setWin] = useState("");
    const [winByDec, setWinByDec] = useState("");
    const [winByKoTko, setWinByKoTko] = useState("");
    const [winBySub, setWinBySub] = useState("");
    const [fightHistory, setFightHistory] = useState([]);
    const [rank, setRank] = useState({});
    const [amateur, setAmateur] = useState(false);
    const amateurs = [{ value: false, label: "NO" }, { value: true, label: "YES" }];

    useEffect(() => {
        setId(fighterData ? fighterData.id : null)
        setCountryFlag(fighterData ? fighterData.countryFlag : null);
        setNC(fighterData ? fighterData.NC : null);
        setReach(fighterData ? fighterData.reach : null);
        setBorn(fighterData ? fighterData.born?.en : null);
        setDivision(fighterData ? fighterData.division : null);
        setCountries(fighterData && fighterData.countries ? fighterData.countries : [])
        setCountryFlag(fighterData ? fighterData.countryFlag : null);
        setDraw(fighterData ? fighterData.draw : null);
        setGender(fighterData ? fighterData.gender : null);
        setLose(fighterData ? fighterData.lose : null);
        setLoseByDec(fighterData ? fighterData.loseByDec : null);
        setLoseByKoTko(fighterData ? fighterData.loseByKoTko : null);
        setLoseBySub(fighterData ? fighterData.loseBySub : null);
        setLastName(fighterData ? fighterData.lastName : null);
        setOrg(fighterData ? fighterData.orga : null);
        setOutOf(fighterData ? fighterData.outOf?.en : null);
        setPhoto(fighterData ? fighterData.photo : '');
        setWeight(fighterData ? fighterData.weight : null);
        setFirstName(fighterData ? fighterData.firstName : null);
        setSignStrikes(fighterData ? fighterData.signStrikes : null);
        setNickname(fighterData ? fighterData.nickname : null);
        setTotalStrikes(fighterData ? fighterData.totalStrikes : null);
        setWin(fighterData ? fighterData.win : null);
        setWinByDec(fighterData ? fighterData.winByDec : null);
        setWinByKoTko(fighterData ? fighterData.winByKoTko : null);
        setWinBySub(fighterData ? fighterData.winBySub : null);
        setFightHistory(fighterData ? fighterData.fightHistory : null);
        setRank(fighterData ? fighterData.rank : null);
        setAmateur(fighterData ? fighterData.amateur : false);
        if (fighterData && fighterData.birthDate) {
            const dateParts = fighterData.birthDate.split("/");

            if (dateParts.length === 3) {
                setBirthMonth(dateParts[0]);
                setBirthDay(dateParts[1]);
                setBirthYear(dateParts[2]);
            }
        }

        if (fighterData && fighterData.height) {

            const tailleRegex = /(\d+)'(\d+)"/;
            const match = fighterData.height.match(tailleRegex);

            if (match) {
                setTailleFeet(match[1]);   // 6
                setTailleInches(match[2]); // 4
            }
        }

    }, [fighterData]);

    const [allDrapeaux, setAllDrapeaux] = useState([]); // Pour stocker tous les pays
    const [selectedPays, setSelectedPays] = useState(""); // Pays sélectionné par l'utilisateur
    const [orgs, setOrgs] = useState([])

    const [newOrg, setNewOrg] = useState(org); // nouvelle orga pour migration
    const [newGender, setNewGender] = useState(gender); // nouvelle division pour migration
    const [newDivision, setNewDivision] = useState(division); // nouvelle division pour migration

    const genders = [{ value: "men", label: "MEN" }, { value: "women", label: "WOMEN" }];




    const divisions = gender === "men" ? [{ value: "1heavy", label: "HEAVYWEIGHT" },
    { value: "2lightheavy", label: "LIGHT-HEAVYWEIGHT" },
    { value: "3middle", label: "MIDDLEWEIGHT" },
    { value: "4welter", label: "WELTERWEIGHT" },
    { value: "5light", label: "LIGHTWEIGHT" },
    { value: "6feather", label: "FEATHERWEIGHT" },
    { value: "7bantam", label: "BANTAMWEIGHT" },
    { value: "8fly", label: "FLYWEIGHT" },
    { value: "catch", label: "CATCH WEIGHT" }] :
        [{ value: "6feather", label: "WOMEN FEATHERWEIGHT" },
        { value: "7bantam", label: "WOMEN BANTAMWEIGHT" },
        { value: "8fly", label: "WOMEN FLYWEIGHT" },
        { value: "9straw", label: "WOMEN STRAWWEIGHT" },
        { value: "10atom", label: "WOMEN ATOMWEIGHT" },
        { value: "catch", label: "CATCH WEIGHT" }];

    const navigate = useNavigate();

    useEffect(() => { // RÉCUPÉRATION ORGA
        const fetchOrgs = async () => {

            const orgsDocRef = doc(db, "fightData", "organisations");

            try {
                const orgsDocSnap = await getDoc(orgsDocRef);

                if (orgsDocSnap.exists()) {
                    const { listOrgasNames } = orgsDocSnap.data(); // Assurez-vous que 'listOrgasNames' est correctement initialisé dans votre document
                    setOrgs(listOrgasNames);
                } else {
                    console.log("Document 'organisations' non trouvé.");
                }
            } catch (error) {
                console.error("Erreur lors de la récupération du document:", error);
            }
        };

        fetchOrgs();
    }, []);

    const [formulaireVisible, setFormulaireVisible] = useState(false);

    /////////// RECHERCHE
    function useDebounce(value, delay) { // DÉLAI POUR RECHERCHE
        const [debouncedValue, setDebouncedValue] = useState(value);

        useEffect(() => {
            const handler = setTimeout(() => {
                setDebouncedValue(value);
            }, delay);

            return () => {
                clearTimeout(handler);
            };
        }, [value, delay]);

        return debouncedValue;
    }

    const [nom1, setNom1] = useState("");
    const [search1, setSearch1] = useState('');
    const debouncedSearchTerm = useDebounce(search1, 500);  // Attend 500ms après la dernière frappe
    const [fighters1, setFighters1] = useState([]);
    const [filteredFighters1, setFilteredFighters1] = useState([]);

    const fetchFighters1 = async (search1, attributesToRetrieve = ['id']) => {
        const functions = getFunctions(app);
        const searchFighters = httpsCallable(functions, 'searchFighters');

        let fetchedData = [];

        try {
            const result = await searchFighters({
                search: search1,
                attributesToRetrieve
            });

            // Vérifiez que result.data contient la propriété 'hits' et qu'elle est un tableau
            if (result.data && Array.isArray(result.data.hits)) {
                fetchedData = result.data.hits.map(hit => ({
                    id: hit.id,
                    ...hit
                }));
            } else {
                console.log('No results from Algolia search or data structure is unexpected.');
            }
        } catch (error) {
            console.error('Error loading favorite fighters data:', error);
        }


        return fetchedData;
    };

    useEffect(() => { // DÉCLENCHÉ FONCTION SEARCHFIGHTER QUAND RECHERCHE
        if (debouncedSearchTerm) {
            const fetchFightersData = async () => {
                const fetchedData = await fetchFighters1(debouncedSearchTerm);
                setFighters1(fetchedData);
                setFilteredFighters1(fetchedData);
            };
            fetchFightersData();
        }
    }, [debouncedSearchTerm]);

    useEffect(() => { // FILTRER RECHERCHE
        const results = fighters1.filter(fighter => fighter.id && fighter.id.toLowerCase().includes(search1.toLowerCase()));
        setFilteredFighters1(results);
    }, [search1]);


    useEffect(() => { // RECHERCHE DONNÉE FIGHTER QUAND ON SÉLECTIONNE UN FIGHTER
        const fetchFighterData = async () => {
            if (nom1) {

                // const docRef = firestore().collection('fighterData').doc(nom1);
                const docRef = doc(db, "fighterData", nom1);
                const docSnapshot = await getDoc(docRef);

                if (docSnapshot.exists) {
                    setFighterData(docSnapshot.data());
                } else {
                    console.log("Aucun combattant trouvé avec l'ID:", nom1);
                    setFighterData(null);
                }
            }
        };

        fetchFighterData();
    }, [nom1, search1]); // Dépendance au nom1, s'exécute chaque fois que nom1 change


    // Charger tous les pays au démarrage de l'application
    useEffect(() => {
        const drapeauCollection = collection(db, "Admin", "AdminFighter", "drapeau");
        const unsubscribe = onSnapshot(drapeauCollection, (querySnapshot) => {
            const drapeaux = [];
            querySnapshot.forEach((document) => {
                drapeaux.push(document.id);
            });
            setAllDrapeaux(drapeaux);
        });
        return () => unsubscribe();
    }, []);

    // Charger l'URL du drapeau lorsque l'utilisateur sélectionne un pays
    useEffect(() => {
        const fetchDrapeau = async () => {
            if (selectedPays) {
                const docRef = doc(db, "Admin", "AdminFighter", "drapeau", selectedPays);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    const data = docSnap.data();
                    setCountryFlag(data.image); // Assurez-vous que le champ s'appelle "image" dans Firestore
                } else {
                    console.log("No such document!");
                }
            }
        };

        fetchDrapeau();
    }, [selectedPays]);

    // Supprimer ou ajouter des pays à la liste countries
    // Fonction pour ajouter un nouveau pays
    const addCountry = (newCountry) => {
        if (newCountry && !countries?.includes(newCountry.trim())) {
            setCountries([...countries, newCountry.trim()]);
        }
    };

    // Fonction pour supprimer un pays
    const removeCountry = (countryToRemove) => {
        setCountries(countries.filter((country) => country !== countryToRemove));
    };


    //// image fighter
    const [imageFile, setImageFile] = useState(null);
    const [noFileSelected, setNoFileSelected] = useState(true);

    const [croppedImageBlob, setCroppedImageBlob] = useState(null);
    const imageRef = useRef();
    const cropperRef = useRef();

    // Gestionnaire pour confirmer le rognage
    const handleCropConfirm = () => {
        cropperRef.current.getCroppedCanvas().toBlob((blob) => {
            setCroppedImageBlob(blob); // Stocker le blob pour la télécharger plus tard
        }, 'image/webp');
    };

    const handleImageChange = (e) => {
        const file = e.target.files[0];

        if (file) {
            setImageFile(file);
            setNoFileSelected(false);

            // Lire l'image et l'afficher pour le recadrage
            const reader = new FileReader();
            reader.onload = (e) => {
                imageRef.current.src = e.target.result;
                if (cropperRef.current) {
                    cropperRef.current.destroy(); // Détruire l'instance précédente
                }
                cropperRef.current = new Cropper(imageRef.current, {
                    aspectRatio: 600 / 300, // Ratio d'aspect souhaité
                    //... autres options
                });
            };
            reader.readAsDataURL(file);
        }
    };

    /// IMAGE = PNG OU WEBP ?
    const [imageType, setImageType] = useState(null);
    useEffect(() => {
        const checkImageType = async () => {
            try {
                // Fetch the image as a blob
                const response = await fetch(photo, { mode: 'cors' });
                if (!response.ok) throw new Error('Network response was not ok');
                const blob = await response.blob();

                // Read the first few bytes of the blob to determine the file type
                const fileReader = new FileReader();
                fileReader.onloadend = () => {
                    const arr = new Uint8Array(fileReader.result).subarray(0, 4);
                    let header = '';
                    for (let i = 0; i < arr.length; i++) {
                        header += arr[i].toString(16);
                    }

                    let type = 'Unknown';
                    if (header === '89504e47') {
                        type = 'PNG';
                    } else if (header.startsWith('52494646')) {
                        type = 'WEBP';
                    } else if (header.startsWith('ffd8ff')) {
                        type = 'JPEG';
                    }
                    console.log('Header:', header); // Log the header for debugging
                    setImageType(type);
                };
                fileReader.readAsArrayBuffer(blob);
            } catch (error) {
                console.error('Error fetching image:', error);
                setImageType('Error');
            }
        };

        checkImageType();
    }, [photo]);

    /// formulaire
    const handleSubmit = async (e) => { // MODIFIER
        e.preventDefault();

        if (imageFile && photo) {
            // Supprimez l'ancienne image de Firebase Storage
            try {
                // Créez une référence à l'ancienne image
                const oldImageRef = ref(storage, photo);
                if (oldImageRef) {
                    await deleteObject(oldImageRef);
                    console.log('Ancienne image supprimée avec succès');
                }
            } catch (error) {
                console.error('Erreur lors de la suppression de l’ancienne image:', error);
            }
        }

        let imageUrl = "";


        if (imageFile) {

            if (croppedImageBlob) {
                try {
                    // Options de compression
                    const options = {
                        maxSizeMB: 0.040, // Taille maximale en Mo (vous pouvez ajuster en fonction de votre besoin)
                        maxWidthOrHeight: 780, // Largeur/hauteur maximale (ajustez selon vos besoins)
                        useWebWorker: true, // Utiliser les Web Workers pour améliorer les performances
                        fileType: 'image/webp', // Convertir en WebP
                    };

                    // Compresser l'image rognée
                    const compressedBlob = await imageCompression(croppedImageBlob, options);
                    const storagePath = `fighterImage/${id}`;
                    const storageRef = ref(storage, storagePath);

                    const snapshot = await uploadBytes(storageRef, compressedBlob, { contentType: 'image/webp' });
                    imageUrl = await getDownloadURL(snapshot.ref);
                    // imageUrl est l'URL de l'image téléchargée
                } catch (error) {
                    console.error("Erreur lors du téléchargement de l'image:", error);
                    return; // Quittez la fonction si le téléchargement échoue
                }
            } else {
                setNoFileSelected(true);
                console.log('Veuillez confirmer le rognage de l’image');
                return;
            }


        } else {
            setNoFileSelected(true);
            console.log('Aucun fichier sélectionné');

        }

        try {

            const docRef = doc(db, "fighterData", id);

            await updateDoc(docRef, {
                amateur: Boolean(amateur),
                NC: Number(NC),
                reach: `${reach}`,
                birthDate: `${birthMonth}/${birthDay}/${birthYear}`,
                born: {
                    en: born
                },
                division,
                countries: countries.length > 0 ? countries : null,
                countryFlag,
                draw: Number(draw),
                gender,
                id: id,
                lose: Number(lose),
                loseByDec: Number(loseByDec),
                loseByKoTko: Number(loseByKoTko),
                loseBySub: Number(loseBySub),
                lastName,
                orga: org,
                outOf: {
                    en: outOf
                },
                photo: imageUrl ? imageUrl : photo,
                weight: Number(weight),
                firstName,
                signStrikes: Number(signStrikes),
                nickname,
                height: `${tailleFeet}'${tailleInches}"`,
                totalStrikes: Number(totalStrikes),
                win: Number(win),
                winByDec: Number(winByDec),
                winByKoTko: Number(winByKoTko),
                winBySub: Number(winBySub),
            });

            setImageFile(null); setAmateur("");
            setNC(""); setReach(""); setBirthDay(""); setBirthMonth(""); setBirthYear(""); setBorn("");
            setCountryFlag(""); setDraw(""); setLose(""); setLoseByDec(""); setLoseByKoTko(""); setLoseBySub("");
            setLastName(""); setOutOf(""); setPhoto(""); setWeight(""); setFirstName(""); setSignStrikes(""); setSelectedPays("");
            setNickname(""); setTailleFeet(""); setTailleInches(""); setTotalStrikes(""); setWin(""); setWinByDec(""); setWinByKoTko("");
            setWinBySub(""); setFormulaireVisible(false);
            alert("Fighter soumis avec succès !");
        } catch (error) {
            console.error("Error adding document: ", error);
        }

    };

    const [showMigrate, setShowMigrate] = useState(false);
    /*
           const handleMigrate = async (e) => { // MODIFIER
            e.preventDefault();
     
                    try {
                        const docOrgaFighterRef = doc(db, "fightData", "organisations", newOrg, gender, newDivision, id);
                        const docRef = doc(db, "fighterData", id);
     
                        await setDoc(docRef, {
                            NC: Number(NC),
                            reach: `${reach}`,
                            birthDate: `${birthMonth}/${birthDay}/${birthYear}`,
                            born,
                            division: newDivision,
                            countryFlag,
                            draw: Number(draw),
                            fightHistory: fightHistory ? fightHistory : [],
                            gender,
                            id: id,
                            lose: Number(lose),
                            loseByDec: Number(loseByDec),
                            loseByKoTko: Number(loseByKoTko),
                            loseBySub: Number(loseBySub),
                            lastName,
                            orga: newOrg,
                            outOf,
                            photo,
                            weight: Number(weight),
                            firstName,
                            rank: rank ? rank : {},
                            signStrikes: Number(signStrikes),
                            nickname,
                            height: `${tailleFeet}'${tailleInches}"`,
                            totalStrikes: Number(totalStrikes),
                            win: Number(win),
                            winByDec: Number(winByDec),
                            winByKoTko: Number(winByKoTko),
                            winBySub: Number(winBySub),
                        });
     
                        await setDoc(docOrgaFighterRef, {
                           fighterRef: docRef
                        });
     
                        setNC(""); setReach(""); setBirthDay(""); setBirthMonth(""); setBirthYear(""); setBorn("");
                        setCountryFlag(""); setDraw(""); setLose(""); setLoseByDec(""); setLoseByKoTko(""); setLoseBySub("");
                        setLastName(""); setOutOf(""); setPhoto(""); setWeight(""); setFirstName(""); setSignStrikes("");
                        setNickname(""); setTailleFeet(""); setTailleInches(""); setTotalStrikes(""); setWin(""); setWinByDec(""); setWinByKoTko("");
                        setWinBySub(""); setShowMigrate(false); setFormulaireVisible(false);
                        alert("Document MIGRÉ avec succès !");
     
                    } catch (error) {
                        console.error("Error adding document: ", error);
                    }
               
        };
    */

    const handleMigrate = async (e) => { // MODIFIER
        e.preventDefault();

        try {
            const docOrgaFighterRef = doc(db, "fightData", "organisations", newOrg, 'fighter', newDivision, id);
            const docRef = doc(db, "fighterData", id);
            await updateDoc(docRef, {
                division: newDivision,
                orga: newOrg,
                gender: newGender,
                fighterRank: {}

            });

            await setDoc(docOrgaFighterRef, {
                fighterRef: docRef
            });

            setNC(""); setReach(""); setBirthDay(""); setBirthMonth(""); setBirthYear(""); setBorn("");
            setCountryFlag(""); setDraw(""); setLose(""); setLoseByDec(""); setLoseByKoTko(""); setLoseBySub("");
            setLastName(""); setOutOf(""); setPhoto(""); setWeight(""); setFirstName(""); setSignStrikes("");
            setNickname(""); setTailleFeet(""); setTailleInches(""); setTotalStrikes(""); setWin(""); setWinByDec(""); setWinByKoTko("");
            setWinBySub(""); setShowMigrate(false); setFormulaireVisible(false);
            alert("Document MIGRÉ avec succès !");

        } catch (error) {
            console.error("Error adding document: ", error);
        }

    };

    const handleDelete = async () => { // SUPPRIMER
        if (window.confirm("Êtes-vous sûr de vouloir supprimer ce document ?")) {
            try {
                const docRef = doc(db, "fighterData", id);
                await deleteDoc(docRef);
                const docOrgaFighterRef = doc(db, "fightData", "organisations", org, gender, division, id);
                await deleteDoc(docOrgaFighterRef);

                setNC(""); setReach(""); setBirthDay(""); setBirthMonth(""); setBirthYear(""); setBorn("");
                setCountryFlag(""); setDraw(""); setLose(""); setLoseByDec(""); setLoseByKoTko(""); setLoseBySub("");
                setLastName(""); setOutOf(""); setPhoto(""); setWeight(""); setFirstName(""); setSignStrikes("");
                setNickname(""); setTailleFeet(""); setTailleInches(""); setTotalStrikes(""); setWin(""); setWinByDec(""); setWinByKoTko("");
                setWinBySub(""); setFormulaireVisible(false);
                alert("Document supprimé avec succès !");
                // Réinitialisez toutes les valeurs et cachez le formulaire si nécessaire
            } catch (error) {
                console.error("Error deleting document: ", error);
            }
        }
    };


    /////// BOUTON
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    const toggleMenu = () => {
        setIsMenuOpen(prevState => !prevState);
    };


    const auth = getAuth();

    const handleLogout = () => { // DÉCONNEXION
        signOut(auth).then(() => {
            navigate("/login");
        }).catch((error) => {
            console.error("Error logging out: ", error);
        });
    };


    return (
        <div style={{
            display: 'flex', justifyContent: 'center', alignItems: 'center',
        }}>

            <div style={{
                display: 'flex', flexDirection: 'column', alignItems: 'center', padding: '20px', fontFamily: "'Arial', sans-serif", justifyContent: 'center',
                borderRadius: '10px', width: '400px', margin: '50px auto',
            }}>

                <h2 style={{ color: '#ff3030', marginBottom: '20px' }}>{t("Search a fighter")}</h2>

                <div style={{ // rechercher
                    width: '100%', marginBottom: '10px'
                }}>

                    <input
                        type="text"
                        value={search1}
                        onChange={(e) => setSearch1(e.target.value)}
                        placeholder={nom1 ? nom1 : "Search a fighter..."}
                        className={nom1 ? "custom-placeholder-input" : null}
                        style={{
                            width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                            borderRadius: '5px', outline: 'none',
                        }}
                    />
                    {search1 && (
                        <div style={{ width: '90%', border: '1.5px solid #ff3030', borderRadius: '5px', maxHeight: '150px', overflowY: 'scroll' }}>
                            {filteredFighters1.map((fighter, index) => (
                                <div
                                    key={index}
                                    style={{ padding: '10px', cursor: 'pointer' }}
                                    onClick={() => {
                                        setNom1(fighter.id)
                                        setFormulaireVisible(true)
                                        setSearch1('');  // optionnel: vider le champ de recherche lors de la sélection
                                    }}
                                >
                                    {fighter.id}
                                </div>
                            ))}
                        </div>
                    )}
                </div>

                {formulaireVisible ?
                    <>
                        <div style={{ flexDirection: 'row', display: 'flex' }}>

                            <button onClick={handleDelete} style={{ margin: '10px', backgroundColor: 'red', color: 'white', padding: '10px 20px', borderRadius: '5px', cursor: 'pointer' }}>
                                {t("DELETE THE FIGHTER")}
                            </button>

                            <button onClick={() => { setFormulaireVisible(false); setShowMigrate(true); }} style={{ margin: '10px', backgroundColor: 'red', color: 'white', padding: '10px 20px', borderRadius: '5px', cursor: 'pointer' }}>
                                {t("Migrate the fighter")}
                            </button>

                        </div>

                        <form onSubmit={handleSubmit} style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                            <div style={{ // id
                                width: '100%', marginBottom: '10px'
                            }}>
                                <label>id :</label>

                                <div style={{ // id
                                    width: '100%', marginBottom: '10px'
                                }}>
                                    <input
                                        type="text"
                                        value={id}
                                        onChange={(e) => setId(e.target.value)}
                                        required
                                        style={{
                                            width: '40%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                            borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                        }}
                                    />
                                </div>
                            </div>

                            <div style={{ // Genre
                                width: '100%', marginBottom: '10px'
                            }}>
                                <label>{t("Gender")} :</label>
                                <select
                                    value={gender}
                                    onChange={(e) => setGender(e.target.value)}
                                    required
                                    style={{
                                        width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none',
                                    }}
                                >
                                    <option value="">{t("Select a gender")}</option>
                                    {genders.map((gender, index) => (
                                        <option key={index} value={gender.value}>
                                            {gender.label}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div style={{ // nom
                                width: '100%', marginBottom: '10px'
                            }}>
                                <label>{t("Last name")} :</label>

                                <div style={{ // nom
                                    width: '100%', marginBottom: '10px'
                                }}>
                                    <input
                                        type="text"
                                        value={lastName}
                                        onChange={(e) => setLastName(e.target.value)}
                                        onBlur={() => setLastName((prev) => prev.trim())}
                                        required
                                        style={{
                                            width: '40%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                            borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                        }}
                                    />
                                </div>
                            </div>

                            <div style={{ // prénom
                                width: '100%', marginBottom: '10px'
                            }}>
                                <label>{t("First name")} :</label>

                                <div style={{ // nom
                                    width: '100%', marginBottom: '10px'
                                }}>
                                    <input
                                        type="text"
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                        onBlur={() => setFirstName((prev) => prev.trim())}
                                        required
                                        style={{
                                            width: '40%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                            borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                        }} />
                                </div>
                            </div>

                            <div style={{ // nickname
                                width: '100%', marginBottom: '10px'
                            }}>
                                <label>nickname :</label>

                                <div style={{ // nickname
                                    width: '100%', marginBottom: '10px'
                                }}>
                                    <input
                                        type="text"
                                        value={nickname}
                                        onChange={(e) => setNickname(e.target.value)}

                                        style={{
                                            width: '40%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                            borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                        }} />
                                </div>
                            </div>

                            <div style={{ // record
                                width: '100%', marginBottom: '10px', flexDirection: 'row', display: 'flex'
                            }}>
                                <div style={{ // victoire
                                    width: '100%', marginBottom: '10px'
                                }}>
                                    <label>{t("Victories")} :</label>
                                    <input
                                        type="text"
                                        value={win}
                                        onChange={(e) => setWin(e.target.value)}
                                        required
                                        style={{
                                            width: '20%', padding: '10px 10px', border: '1.5px solid #ff3030', margin: '5px 0',
                                            borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                        }} />
                                </div>

                                <div style={{ // défaite
                                    width: '100%', marginBottom: '10px'
                                }}>
                                    <label>{t("Defeats")} :</label>
                                    <input
                                        type="text"
                                        value={lose}
                                        onChange={(e) => setLose(e.target.value)}
                                        required
                                        style={{
                                            width: '20%', padding: '10px 10px', border: '1.5px solid #ff3030', margin: '5px 0',
                                            borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                        }} />
                                </div>

                                <div style={{ // draw
                                    width: '100%', marginBottom: '10px'
                                }}>
                                    <label>{t("Draws")} :</label>
                                    <input
                                        type="text"
                                        value={draw}
                                        onChange={(e) => setDraw(e.target.value)}
                                        required
                                        style={{
                                            width: '20%', padding: '10px 10px', border: '1.5px solid #ff3030', margin: '5px 0',
                                            borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                        }} />
                                </div>

                                <div style={{ // NO CONTEST
                                    width: '100%', marginBottom: '10px'
                                }}>
                                    <label>NO CONTEST :</label>
                                    <input
                                        type="text"
                                        value={NC}
                                        onChange={(e) => setNC(e.target.value)}
                                        required
                                        style={{
                                            width: '20%', padding: '10px 10px', border: '1.5px solid #ff3030', margin: '5px 0',
                                            borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                        }} />
                                </div>
                            </div>

                            <div style={{ // Organisation
                                width: '100%', marginBottom: '10px'
                            }}>
                                <label>Organization :</label>
                                <select
                                    value={org}
                                    onChange={(e) => setOrg(e.target.value)}
                                    required
                                    style={{
                                        width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none',
                                    }}
                                >
                                    <option value="">{t("Select an organization")}</option>
                                    {orgs.map((org, index) => (
                                        <option key={index} value={org}>
                                            {org}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div style={{ // AMATEUR
                                width: '100%', marginBottom: '10px'
                            }}>
                                <label style={{ marginRight: 10 }}>{t('Amateur')} :</label>

                                <select
                                    value={amateur}
                                    onChange={(e) => setAmateur(e.target.value === 'true')}
                                    required
                                    style={{
                                        width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none',
                                    }}
                                >
                                    <option value="">{t("Select an answer")}</option>
                                    {amateurs.map((amateur, index) => (
                                        <option key={index} value={amateur.value}>
                                            {amateur.label}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div style={{ // division
                                width: '100%', marginBottom: '10px'
                            }}>
                                <label>{t("Division")} :</label>
                                <select
                                    value={division}
                                    onChange={(e) => setDivision(e.target.value)}
                                    required
                                    style={{
                                        width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none',
                                    }}
                                >
                                    <option value="">{t("Select a division")}</option>
                                    {divisions.map((division, index) => (
                                        <option key={index} value={division.value}>
                                            {division.label}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div style={{ // DRAPEAU
                                width: '100%', marginBottom: '10px'
                            }}>
                                <label>{t("Flag")} :</label>
                                <select
                                    value={selectedPays}
                                    onChange={(e) => setSelectedPays(e.target.value)}
                                    style={{
                                        width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                        borderRadius: '5px', outline: 'none',
                                    }}
                                >
                                    <option value="">{t("Select a flag")}</option>
                                    {allDrapeaux.map((pays, index) => (
                                        <option key={index} value={pays}>
                                            {pays}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div style={{ // ARRAY COUNTRY
                                margin: '20px auto', padding: '20px', maxWidth: '400px', backgroundColor: '#f4f4f9', borderRadius: '8px',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', fontFamily: 'Arial, sans-serif',
                            }}>
                                <h3 style={{ fontSize: '24px', textAlign: 'center', color: '#333', marginBottom: '20px', }}>Countries List</h3>

                                <ul style={{ listStyle: 'none', padding: 0 }}>
                                    {countries?.map((country, index) => (
                                        <li key={index} style={{
                                            backgroundColor: '#fff', margin: '10px 0', padding: '10px', borderRadius: '4px', display: 'flex',
                                            justifyContent: 'space-between', alignItems: 'center', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                                        }}>
                                            {country}
                                            <button type="button"
                                                style={{
                                                    backgroundColor: '#ff6b6b', border: 'none', color: 'white', padding: '8px 12px',
                                                    borderRadius: '4px', cursor: 'pointer', transition: 'background-color 0.3s ease',
                                                }}
                                                onClick={() => removeCountry(country)}
                                            >
                                                Remove
                                            </button>
                                        </li>
                                    ))}
                                </ul>

                                <AddCountryForm onAdd={addCountry} />
                            </div>

                            <div style={{ //photo
                                width: '100%', marginBottom: '10px'
                            }}>
                                <label>{t("Photo")} :</label>
                                <div style={{
                                    flexDirection: 'row', alignItems: 'center', justifyContent: 'center', display: 'flex',
                                }}>
                                    <input
                                        type="text"
                                        value={photo}
                                        onChange={(e) => setPhoto(e.target.value)}

                                        style={{
                                            width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                            borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                        }} />
                                    {imageType ?
                                        <>
                                            <p style={{ marginLeft: 10 }}>Image Type:</p>

                                            <p style={{ marginLeft: 10, fontWeight: "bolder", color: imageType === 'WEBP' ? 'blue' : 'orange' }}>{imageType}</p>
                                        </>
                                        : null}</div>
                            </div>

                            <div style={{ // ou
                                width: '50%', marginBottom: '10px',
                            }}>
                                <label>or</label>
                            </div>

                            <div style={{
                                width: '100%', marginBottom: '10px',
                            }}>
                                <label htmlFor="file-upload" style={{ marginBottom: '5px', display: 'block', fontSize: 14, color: 'grey' }}>
                                    {t("Choose another image")}:
                                </label>
                                <input
                                    id="file-upload"
                                    type="file"
                                    onChange={handleImageChange}
                                    style={{
                                        width: '90%', padding: '10px 15px', border: '1px solid #cccccc', borderRadius: '5px', outline: 'none',
                                    }}
                                />
                            </div>

                            {fighterData.photo ?
                                <div>
                                    <img
                                        src={fighterData.photo}
                                        style={{
                                            width: '100%', borderRadius: '5px', marginTop: '10px'
                                        }} />
                                </div>
                                : null}

                            {!noFileSelected ?
                                <button style={{ width: '50%', marginBottom: '10px' }} type="button" onClick={handleCropConfirm}>{t("Confirm the crop")}</button>
                                : null}

                            {imageRef && !noFileSelected ?
                                <div style={{ width: '500px', height: '500px', overflow: 'hidden' }}>
                                    <img ref={imageRef} style={{ maxWidth: '100%', maxHeight: '100%' }} alt="Preview" />
                                </div>
                                : null}

                            <div style={{ // DATE de naissance
                                width: '100%',
                            }}>
                                <label>{t("Date of birth")} :</label>

                                <div style={{
                                    width: '40%', marginBottom: '10px', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', display: 'flex',
                                }}>
                                    <div style={{ // MOIS
                                        marginBottom: '10px', flexDirection: 'column', display: 'flex',
                                    }}>
                                        <input
                                            type="text"
                                            value={birthMonth}
                                            onChange={(e) => setBirthMonth(e.target.value)}
                                            required
                                            style={{
                                                width: '40%', padding: '10px 5px', border: '1.5px solid #ff3030', margin: '5px 0',
                                                borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                            }} />
                                        <label style={{ fontSize: 15, }}>{t("Month")}</label>

                                    </div>



                                    <div style={{ // jour
                                        marginBottom: '10px', flexDirection: 'column', display: 'flex', marginLeft: 10,
                                    }}>
                                        <input
                                            type="text"
                                            value={birthDay}
                                            onChange={(e) => setBirthDay(e.target.value)}
                                            required
                                            style={{
                                                width: '40%', padding: '10px 5px', border: '1.5px solid #ff3030', margin: '5px 0',
                                                borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                            }} />
                                        <label style={{ fontSize: 15, }}>{t("Day")}</label>
                                    </div>



                                    <div style={{ // année
                                        marginBottom: '10px', flexDirection: 'column', display: 'flex', marginLeft: 10,
                                    }}>
                                        <input
                                            type="text"
                                            value={birthYear}
                                            onChange={(e) => setBirthYear(e.target.value)}
                                            required
                                            style={{
                                                width: '70%', padding: '10px 10px', border: '1.5px solid #ff3030', margin: '5px 0',
                                                borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                            }} />
                                        <label style={{ fontSize: 15, }}>{t("Year")}</label>
                                    </div>
                                </div>


                            </div>

                            <div style={{ // born
                                width: '100%', marginBottom: '10px'
                            }}>
                                <label>{t("Born in")} :</label>

                                <div style={{ //
                                    width: '100%', marginBottom: '10px',
                                }}>
                                    <input
                                        type="text"
                                        value={born}
                                        onChange={(e) => setBorn(e.target.value)}
                                        required
                                        style={{
                                            width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                            borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                        }} />
                                </div>
                            </div>

                            <div style={{ // FIGHTING OUT OF
                                width: '100%', marginBottom: '10px'
                            }}>
                                <label>FIGHTING OUT OF :</label>

                                <div style={{ // born
                                    width: '100%', marginBottom: '10px'
                                }}>
                                    <input
                                        type="text"
                                        value={outOf}
                                        onChange={(e) => setOutOf(e.target.value)}
                                        required
                                        style={{
                                            width: '40%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                            borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                        }} />
                                </div>
                            </div>

                            <div style={{ // height
                                width: '100%', marginBottom: '10px'
                            }}>
                                <label>height :</label>

                                <div style={{ // feet
                                    width: '100%', marginBottom: '10px', flexDirection: 'row'
                                }}>
                                    <input
                                        type="text"
                                        value={tailleFeet}
                                        onChange={(e) => setTailleFeet(e.target.value)}
                                        required
                                        style={{
                                            width: '5%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                            borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                        }} />
                                    <label style={{ marginLeft: 5, fontSize: 30 }}>'</label>
                                    <input
                                        type="text"
                                        value={tailleInches}
                                        onChange={(e) => setTailleInches(e.target.value)}
                                        required
                                        style={{
                                            width: '5%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                            borderRadius: '5px', outline: 'none', marginLeft: 5, fontSize: 15, fontWeight: 'bold'
                                        }} />
                                    <label style={{ marginLeft: 5, fontSize: 30 }}>"</label>
                                </div>




                            </div>

                            <div style={{ // weight
                                width: '100%', marginBottom: '10px'
                            }}>
                                <label>weight :</label>

                                <div style={{ // weight
                                    width: '100%', marginBottom: '10px'
                                }}>
                                    <input
                                        type="text"
                                        value={weight}
                                        onChange={(e) => setWeight(e.target.value)}
                                        required
                                        style={{
                                            width: '40%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                            borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                        }} />
                                </div>
                            </div>

                            <div style={{ // reach
                                width: '100%', marginBottom: '10px'
                            }}>
                                <label>reach :</label>

                                <div style={{
                                    width: '100%', marginBottom: '10px', flexDirection: 'row'
                                }}>

                                    <input
                                        type="text"
                                        value={reach}
                                        onChange={(e) => setReach(e.target.value)}
                                        required
                                        style={{
                                            width: '10%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                            borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                        }} />
                                    <label style={{ marginLeft: 5, fontSize: 30 }}>"</label>
                                </div>

                            </div>

                            <div style={{ // strike
                                width: '100%', marginBottom: '10px'
                            }}>


                                <div style={{
                                    width: '100%', marginBottom: '10px', flexDirection: 'row'
                                }}>
                                    <label>Total strike : </label>
                                    <div style={{ // Total strike
                                        width: '100%', marginBottom: '10px'
                                    }}>
                                        <input
                                            type="text"
                                            value={totalStrikes}
                                            onChange={(e) => setTotalStrikes(e.target.value)}

                                            style={{
                                                width: '40%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                                borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                            }} />
                                    </div>

                                    <label>Significative strike : </label>

                                    <div style={{ // Significative strike
                                        width: '100%', marginBottom: '10px'
                                    }}>
                                        <input
                                            type="text"
                                            value={signStrikes}
                                            onChange={(e) => setSignStrikes(e.target.value)}
                                            style={{
                                                width: '40%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                                borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                            }} />
                                    </div>
                                </div>




                            </div>

                            <div style={{ // WIN BY
                                width: '100%', marginBottom: '10px'
                            }}>
                                <label>{t("Victories by")} :</label>

                                <div style={{
                                    width: '75%', marginBottom: '10px', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', display: 'flex',
                                }}>
                                    <div style={{ // ko tko
                                        marginBottom: '10px', flexDirection: 'column', display: 'flex',
                                    }}>

                                        <input
                                            type="text"
                                            value={winByKoTko}
                                            onChange={(e) => setWinByKoTko(e.target.value)}
                                            required
                                            style={{
                                                width: '20%', padding: '10px 10px', border: '1.5px solid #ff3030', margin: '5px 0',
                                                borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                            }} />
                                        <label>KO/TKO</label>

                                    </div>

                                    <div style={{ // soumission
                                        marginBottom: '10px', flexDirection: 'column', display: 'flex',
                                    }}>

                                        <input
                                            type="text"
                                            value={winBySub}
                                            onChange={(e) => setWinBySub(e.target.value)}
                                            required
                                            style={{
                                                width: '20%', padding: '10px 10px', border: '1.5px solid #ff3030', margin: '5px 0',
                                                borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                            }} />
                                        <label>Submission</label>

                                    </div>

                                    <div style={{ // décision
                                        marginBottom: '10px', flexDirection: 'column', display: 'flex',
                                    }}>

                                        <input
                                            type="text"
                                            value={winByDec}
                                            onChange={(e) => setWinByDec(e.target.value)}
                                            required
                                            style={{
                                                width: '20%', padding: '10px 10px', border: '1.5px solid #ff3030', margin: '5px 0',
                                                borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                            }} />
                                        <label>Decision</label>
                                    </div>
                                </div>
                            </div>

                            <div style={{ // lose BY
                                width: '100%', marginBottom: '10px'
                            }}>
                                <label>{t("Defeats by")} :</label>

                                <div style={{
                                    width: '75%', marginBottom: '10px', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', display: 'flex',
                                }}>
                                    <div style={{ // ko tko
                                        marginBottom: '10px', flexDirection: 'column', display: 'flex',
                                    }}>

                                        <input
                                            type="text"
                                            value={loseByKoTko}
                                            onChange={(e) => setLoseByKoTko(e.target.value)}
                                            required
                                            style={{
                                                width: '20%', padding: '10px 10px', border: '1.5px solid #ff3030', margin: '5px 0',
                                                borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                            }} />
                                        <label>KO/TKO</label>

                                    </div>

                                    <div style={{ // soumission
                                        marginBottom: '10px', flexDirection: 'column', display: 'flex',
                                    }}>

                                        <input
                                            type="text"
                                            value={loseBySub}
                                            onChange={(e) => setLoseBySub(e.target.value)}
                                            required
                                            style={{
                                                width: '20%', padding: '10px 10px', border: '1.5px solid #ff3030', margin: '5px 0',
                                                borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                            }} />
                                        <label>Submission</label>

                                    </div>

                                    <div style={{ // décision
                                        marginBottom: '10px', flexDirection: 'column', display: 'flex',
                                    }}>

                                        <input
                                            type="text"
                                            value={loseByDec}
                                            onChange={(e) => setLoseByDec(e.target.value)}
                                            required
                                            style={{
                                                width: '20%', padding: '10px 10px', border: '1.5px solid #ff3030', margin: '5px 0',
                                                borderRadius: '5px', outline: 'none', fontSize: 15, fontWeight: 'bold'
                                            }} />
                                        <label>Decision</label>

                                    </div>
                                </div>
                            </div>



                            <button
                                type="submit"
                                style={{
                                    padding: '10px 20px', border: 'none', backgroundColor: '#ff3030',
                                    color: 'white', borderRadius: '25px', marginTop: '10px', cursor: 'pointer',
                                    outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder'
                                }}
                            >
                                {t("Submit")}
                            </button>
                        </form>
                    </>
                    : null}

                {showMigrate ?
                    <form onSubmit={handleMigrate} style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <div style={{ // Organisation
                            width: '100%', marginBottom: '10px'
                        }}>
                            <label>{t("Migrate to another organization")} :</label>
                            <select
                                value={newOrg}
                                onChange={(e) => setNewOrg(e.target.value)}
                                required
                                style={{
                                    width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                    borderRadius: '5px', outline: 'none',
                                }}
                            >
                                <option value="">{t("Select an organization")}</option>
                                {orgs.map((org, index) => (
                                    <option key={index} value={org}>
                                        {org}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div style={{ // division
                            width: '100%', marginBottom: '10px'
                        }}>
                            <label>{t("Migrate to another division")} :</label>
                            <select
                                value={newDivision}
                                onChange={(e) => setNewDivision(e.target.value)}
                                required
                                style={{
                                    width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                    borderRadius: '5px', outline: 'none',
                                }}
                            >
                                <option value="">{t("Select a division")}</option>
                                {divisions.map((division, index) => (
                                    <option key={index} value={division.value}>
                                        {division.label}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div style={{ // Genre
                            width: '100%', marginBottom: '10px'
                        }}>
                            <label>{t("Gender")} :</label>
                            <select
                                value={newGender}
                                onChange={(e) => setNewGender(e.target.value)}
                                required
                                style={{
                                    width: '90%', padding: '10px 15px', border: '1.5px solid #ff3030', margin: '5px 0',
                                    borderRadius: '5px', outline: 'none',
                                }}
                            >
                                <option value="">{t("Select a gender")}</option>
                                {genders.map((gender, index) => (
                                    <option key={index} value={gender.value}>
                                        {gender.label}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <button
                            type="submit"
                            style={{
                                padding: '10px 20px', border: 'none', backgroundColor: '#ff3030',
                                color: 'white', borderRadius: '25px', marginTop: '10px', cursor: 'pointer',
                                outline: 'none', transition: 'background-color 0.3s', fontWeight: 'bolder'
                            }}
                        >
                            {t("MIGRATE THE FIGHTER")}
                        </button>
                    </form>

                    : null}

            </div>
        </div>

    );
}

const AddCountryForm = ({ onAdd }) => {
    const [newCountry, setNewCountry] = useState('');

    const handleAdd = (e) => {
        e.preventDefault();
        onAdd(newCountry);
        setNewCountry(''); // Réinitialise le champ de saisie après l'ajout
    };

    return (
        <div style={{
            display: 'flex', marginTop: '20px',
        }}>
            <input
                type="text" value={newCountry}
                onChange={(e) => setNewCountry(e.target.value)}
                placeholder="Add a new country"
                style={{
                    flex: 1, padding: '10px', border: '1px solid #ccc', borderRadius: '4px', marginRight: '10px', boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
                }}
            />
            <button type="button" onClick={handleAdd} style={{
                backgroundColor: '#4caf50', border: 'none',
                color: 'white', padding: '10px 15px', borderRadius: '4px',
                cursor: 'pointer', transition: 'background-color 0.3s ease',
            }}>
                Add
            </button>
        </div>
    );
};